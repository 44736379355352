import { Field, Formik, FormikActions, FormikProps } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import * as Yup from 'yup';
import { listAcountsByEmail } from '../../api/auth/authClient';
import { useAuth } from '../../providers/auth/AuthProvider';
import { useTenant } from '../../providers/tenant/TenantProvider';
import { useLoginData } from '../../providers/login/LoginDataProvider';
import { Input } from '@material-ui/core';
import { ScLoginContainer, ScTitle, ScForm, ScSubmitBtn } from './style';

type CodeValues = {
  code: string;
};

const initialValues: CodeValues = {
  code: ''
};

const EmailCodeForm = () => {
  const { t } = useTranslation();
  const { tenantId } = useTenant();
  const { login } = useAuth();
  const { email } = useLoginData();

  const onSubmit = useCallback(
    async (values: CodeValues, actions: FormikActions<CodeValues>) => {
      if (email == null) {
        actions.setSubmitting(false);
        return;
      }

      const { promise } = listAcountsByEmail(tenantId, email, values.code);
      const { data, error } = await promise;
      if (error != null || data == null || data.length === 0) {
        console.log(t('AUTH__FAILED_TO_LOGIN_USER_NOT_FOUND')); //TODO
      } else {
        const isLoggedIn: boolean = await login(data[0].entityId, email, values.code);
        if (!isLoggedIn) {
          console.log(t('AUTH__FAILED_TO_LOGIN')); //TODO
        }
      }
      actions.setSubmitting(false);
    },
    [email, login, tenantId, t]
  );

  const formSchema = useMemo(
    () =>
      Yup.object().shape({
        code: Yup.string()
          .matches(/\d+/, { excludeEmptyString: true, message: t('FORM__NUMERIC') })
          .min(6, t('FORM__MINIMUM', { min: '6' }))
          .max(6, t('FORM__MAXIMUM', { max: '6' }))
          .required(t('FORM__REQUIRED'))
      }),
    [t]
  );

  return email == null ? (
    <Redirect
      to={{
        pathname: '/login'
      }}
    />
  ) : (
    <ScLoginContainer>
      <ScTitle>{t('AUTH__SIGN_IN')}</ScTitle>
      <p>{email}</p>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={true}
        validateOnChange={false}
        validationSchema={formSchema}
      >
        {(formProps: FormikProps<CodeValues>) => {
          return (
            <ScForm noValidate onSubmit={formProps.handleSubmit}>
              <Field
                component={Input}
                disabled={formProps.isSubmitting}
                name="code"
                label={t('AUTH__CODE_LABEL')}
                size="medium"
                type="text"
                variant="outlined"
                value={formProps.values.code}
                autoFocus
              />

              <ScSubmitBtn
                disabled={formProps.isSubmitting}
                onClick={formProps.handleSubmit as any}
                variant="contained"
              >
                {t('AUTH__CONTINUE')}
              </ScSubmitBtn>
            </ScForm>
          );
        }}
      </Formik>
    </ScLoginContainer>
  );
};
export default EmailCodeForm;
