import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AppProviders } from './context';
import './index.css';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

ReactDOM.render(
    <AppProviders>
      <App />
    </AppProviders>,
  document.getElementById('root')
);

