import React from 'react';
import styled from 'styled-components';
import { POS_PRIMARY_COLOR } from '../utils/constants';

const now = new Date();
const currentYear = now.getFullYear();

const ScFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 15px;
  background-color: ${POS_PRIMARY_COLOR};
  color: #FFFF;
`;

const Footer = () => (
  <ScFooter>
    © {currentYear} XPower N.V.
  </ScFooter>
);

export default Footer;
