import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { POS_PRIMARY_COLOR, POS_SECONDARY_COLOR } from '../../utils/constants';

const ScLoginContainer = styled.div`
    max-width: 80vh;
    background-color: #FFFF;
    padding: 5vh;
    margin: 0 auto;
    border: 2px solid rgba(43, 76, 107, 0.13);
`;

const ScTitle = styled.div`
  font-size: 1.5rem;
  margin: 1.5rem 0;
  font-weight: 600;
`;

const ScForm = styled.form`
  display: grid;
  grid-template-rows: repeat(1fr);
  grid-row-gap: 50px;
`;

const ScSubmitBtn = styled(Button)`
  background-color: ${POS_PRIMARY_COLOR};
  color: white;
  width: 100%;
  :hover {
    background-color: ${POS_SECONDARY_COLOR};
  }
  :disabled {
    color: white;
  }
`;

export { ScLoginContainer, ScTitle, ScForm, ScSubmitBtn };