import React from 'react';
import { Route, Switch } from 'react-router';
import { StylesProvider } from '@material-ui/core/styles';
import UnauthenticatedApp from './layout/UnauthenticatedApp';
// import AuthenticatedApp from './layout/AuthenticatedApp';

const App: React.FC = () => {
  return (
    <StylesProvider injectFirst>
      <Switch>
        <Route path="/login" component={UnauthenticatedApp} />
        {/* <Route path="/" component={AuthenticatedApp} /> */}
        <Route path="/" component={UnauthenticatedApp} />
      </Switch>
    </StylesProvider>
  );
};

export default App;
