export type Hyperfetch = <T>(url: string, options?: any) => FetchResponse<T>;

export type ResponseInterceptor = (
  res: Response,
  options: RequestInit
) => Response | Promise<Response>;

export type FetchValue<T> =
  | {
      data?: T;
      error?: undefined;
      errorData?: undefined;
    }
  | { data?: undefined; error: HttpErrors | OtherErrors; errorData?: unknown };

export type FetchResponse<T> = {
  promise: Promise<FetchValue<T>>;
  abort: () => void;
};

export enum OtherErrors {
  FetchError
}

export enum HttpErrors {
  ForbiddenRequestError,
  NotFoundRequestError,
  UnknownRequestError,
  UnauthenticatedRequestError,
  InvalidRequestError
}

//
// Globals
//
const responseInterceptors: ResponseInterceptor[] = [];

export const registerResponseInterceptor = (interceptor: ResponseInterceptor): void => {
  responseInterceptors.push(interceptor);
};

export const unregisterResponseInterceptor = (interceptor: ResponseInterceptor): void => {
  const index = responseInterceptors.indexOf(interceptor);
  if (index >= 0) {
    responseInterceptors.splice(index, 1);
  }
};

export const json = (body: Object) => {
  return new Blob([JSON.stringify(body)], { type: 'application/json' });
};

export const hyperfetch = <T>(url: string, options: RequestInit = {}): FetchResponse<T> => {
  // Cancel logic
  const controller = new AbortController();

  // do fetch
  let responsePromise: Promise<Response> = fetch(url, {
    ...options,
    headers: new Headers(options.headers || {}),
    signal: controller.signal
  });

  // Register response interceptors
  responseInterceptors.forEach(interceptor => {
    responsePromise = responsePromise.then((res: Response) => interceptor(res, options));
  });

  // handle result
  const returnPromise: Promise<FetchValue<T>> = responsePromise
    .then(async (response: Response) => {
      if (response.status < 300) {
        return {
          data: response.status === 200 ? (((await response.json()) as unknown) as T) : undefined
        };
      } else {
        switch (response.status) {
          case 400:
            return {
              error: HttpErrors.InvalidRequestError,
              errorData: response.json()
            };
          case 401:
            return { error: HttpErrors.UnauthenticatedRequestError };
          case 403:
            return { error: HttpErrors.ForbiddenRequestError };
          case 404:
            return { error: HttpErrors.NotFoundRequestError };
          default:
            return { error: HttpErrors.UnknownRequestError };
        }
      }
    })
    .catch(e => {
      if (e instanceof DOMException) {
        throw e;
      }
      return { error: OtherErrors.FetchError, errorData: e };
    });

  return {
    promise: returnPromise,
    abort: () => {
      controller.abort();
    }
  };
};

export const failOnNullOrError = async <T>({ promise }: FetchResponse<T>): Promise<T> => {
  const { data, error } = await promise;
  if (data != null && error == null) {
    return data;
  } else {
    throw new Error('Failed request');
  }
};
