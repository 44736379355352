export const POS_APP_BACKGROUND_COLOR = '#fafbfc';
export const POS_PRIMARY_COLOR = '#2B4C6B';
export const POS_SECONDARY_COLOR = '#007edb';

export const FALLBACK_LANGUAGE = 'en';

export const BREAKPOINTS = {
  xs: 320,
  sm: 576,
  md: 768,
  lg: 1024
};
