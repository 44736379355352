import React from 'react';
import styled from 'styled-components/macro';
import { CircularProgress } from '@material-ui/core';

const ScWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 50px;
`;

export const Loading = ({ className }: { className?: string }) => {
  return (
    <ScWrapper className={className}>
      <CircularProgress />
    </ScWrapper>
  );
};
