// import { UrlQueryProvider } from '@hypercharge/digitaldealer-commons/lib/providers/router/UrlQueryProvider';
// import { SearchParamsProvider } from '@hypercharge/digitaldealer-commons/lib/providers/search/SearchParamsProvider';
import React, { Suspense } from 'react';
import { Router } from 'react-router';
import { Loading } from '../components/commons/Loading';
import { LoginDataProvider } from '../providers/login/LoginDataProvider';
import { AuthProvider } from '../providers/auth/AuthProvider';
import { OnlineProvider } from '../providers/online/OnlineProvider';
import { Splash } from './splash/Splash';
import { history } from '../providers/router/history';
import { SplashProvider } from '../providers/splash/SplashProvider';
import { TenantProvider } from '../providers/tenant/TenantProvider';
import { UserProvider } from '../providers/user/UserProvider';
import './i18n'; // import i18n just so that it gets bundled

export const AppProviders = ({ children }: any) => {
  return (
    <TenantProvider>
      <Router history={history}>
          {/* <UrlQueryProvider> */}
            <Suspense fallback={<Loading />}>
              <SplashProvider>
                <OnlineProvider>
                  <AuthProvider>{children}</AuthProvider>
                </OnlineProvider>
              </SplashProvider>
            </Suspense>
          {/* </UrlQueryProvider> */}
      </Router>
    </TenantProvider>
  );
};

export const AuthenticatedAppProviders = ({ children }: any) => {
  return (
    <UserProvider>
        <Splash>{children}</Splash>
    </UserProvider>
  );
};

export const UnauthenticatedAppProviders = ({ children }: any) => {
  return (
    <LoginDataProvider>
      <Splash>{children}</Splash>
    </LoginDataProvider>
  );
};
