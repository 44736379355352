import { toUpper } from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import styled from 'styled-components';
import { UnauthenticatedAppProviders } from '../context';
import { useAuth } from '../providers/auth/AuthProvider';
import { useTenant } from '../providers/tenant/TenantProvider';
import EmailCodeForm from '../components/login/EmailCodeForm';
import EmailLoginForm from '../components/login/EmailLoginForm';
import ErrorBoundary from '../components/ErrorBoundary';
import Footer from './Footer';
import { ScLayout, ScContent } from './Commons';

const ScLogo = styled.img`
  max-width: 200px;
`;

const ScLogoWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
`;

const UnauthenticatedApp = ({ location }: RouteComponentProps<any>) => {
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation();
  const { tenantId, logoUrl } = useTenant();
  const { from } = location.state || { from: { pathname: '/' } };

  return !isAuthenticated ? (
    <UnauthenticatedAppProviders>
      <ScLayout>
        <Helmet title={`${toUpper(tenantId)} POS: ${t('AUTH__SIGN_IN')}`} />
        <ScContent>
          <ScLogoWrapper>
            <ScLogo src={logoUrl}/>
          </ScLogoWrapper>
            <ErrorBoundary>
              <Switch>
                <Route path="/login/code" component={EmailCodeForm} />
                <Route path="/login" component={EmailLoginForm} />
                <Redirect to="/login" />
              </Switch>
            </ErrorBoundary>
        </ScContent>
      </ScLayout>
      <Footer />
    </UnauthenticatedAppProviders>
  ) : (
    <Redirect to={from} />
  );
};

export default UnauthenticatedApp;
