import { history } from '../../providers/router/history';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';
import { requestEmailCode } from '../../api/auth/authClient';
import { useTenant } from '../../providers/tenant/TenantProvider';
import { useLoginData } from '../../providers/login/LoginDataProvider';
import { ScLoginContainer, ScTitle, ScForm, ScSubmitBtn } from './style';

type LoginValues = {
  email: string;
};

const initialValues: LoginValues = {
  email: ''
};

const EmailLoginForm = ({ location }: RouteComponentProps<any>) => {
  const { t } = useTranslation();
  const { tenantId } = useTenant();
  const { setEmail } = useLoginData();

  const onSubmit = useCallback(
    async (values: LoginValues, actions: FormikActions<LoginValues>) => {
      const { promise } = requestEmailCode(tenantId, values.email);
      await promise.then(({ error }) => {
        if (error == null) {
          setEmail(values.email);
          history.push('/login/code', location.state);
        } else {
          console.log(t('AUTH__REQUEST_CODE_ERROR')); //TODO
        }
        actions.setSubmitting(false);
      });
    },
    [setEmail, tenantId, location.state, t]
  );

  const formSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .email(t('FORM__INVALID_EMAIL'))
          .required(t('FORM__REQUIRED'))
      }),
    [t]
  );

  return (
    <ScLoginContainer>
      <ScTitle>{t('AUTH__SIGN_IN')}</ScTitle>
      <p>Enter your email to log in.</p>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={true}
        validateOnChange={false}
        validationSchema={formSchema}
      >
        {(formProps: FormikProps<LoginValues>) => {
          return (
            <ScForm noValidate onSubmit={formProps.handleSubmit}>
              <Field
                id="email"
                name="email"
                label={t('AUTH__EMAIL_PLACEHOLDER')}
                variant="outlined"
                component={TextField}
                disabled={formProps.isSubmitting}
                size="medium"
                type="text"
                value={formProps.values.email}
              />
                <ScSubmitBtn
                  disabled={formProps.isSubmitting}
                  onClick={formProps.handleSubmit as any}
                >
                  {t('AUTH__CONTINUE')}
                </ScSubmitBtn>
            </ScForm>
          );
        }}
      </Formik>
    </ScLoginContainer>
  );
};
export default EmailLoginForm;
