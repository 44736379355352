

import styled from 'styled-components/macro';

const ScLayout = styled.div`
  min-height: 100%;
`;

const ScContent = styled.div`
  padding-top: 7rem;
  padding-bottom: 2rem;
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
`;

export { ScLayout, ScContent };